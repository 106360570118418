/* .spinner {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 9px solid;
    border-color: #dbdcef;
    border-right-color: #474bff;
    animation: spinner-d3wgkg 0.3s infinite linear;
 }
 
 @keyframes spinner-d3wgkg {
    to {
       transform: rotate(1turn);
    }
 }
 */
.spinner {
   width: 40px;
   height: 40px;
   border-radius: 50%;
   background: radial-gradient(farthest-side, #474bff 94%, #0000) top/6.4px 6.4px no-repeat,
      conic-gradient(#0000 30%, #474bff);
   -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 6.4px), #000 0);
   animation: spinner-c7wet2 0.3s infinite linear;
}

@keyframes spinner-c7wet2 {
   100% {
      transform: rotate(1turn);
   }
}