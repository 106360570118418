* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

:root {
  --sidebarbg: #34444c;
  --coremainbg: #eaedf7;
  --corewhite: #fff;
  --corecardtital: #1d212f;
  --coreIconbg: rgba(226, 226, 226, 0.13);
  --primary: #4168f5;
}

a:hover {
  text-decoration: none !important;
}

.container {
  display: flex;
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  max-width: 100% !important;
}

main {
  width: 100%;
  /* background-color: var(--coremainbg); */
  /* height: 100vh; */
  /* overflow: auto; */
  /* overflow-x: none; */
  overflow-x: hidden;
}

.sidebar {
  /* background: var(--sidebarbg); */
  color: hsla(0, 0%, 100%, .4);
  /* width: 240px; */
  /* height: 89vh; */
  transition: all 0.5s;
  overflow-x: hidden;
  overflow-y: scroll;
}

.top_section {
  display: flex;
  align-items: center;
  padding: 11px 42px;
  background-color: var(--corewhite);
  position: sticky !important;
  top: 0;
  z-index: 9999999 !important;
  border-bottom: solid 1px #e7eaf3;
  justify-content: center;
}

.logo {
  font-size: 21px;
  color: var(--corewhite);
  font-weight: 600;
}

.bars {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
  cursor: pointer;
  color: #000 !important;
}

.link_inactive {
  gap: 7px;
  transition: all 0.5s;
  align-items: center;
  /* color: #5B6B79 !important; */
  color: #fff !important;
}

.link_inactive .css-84zodg {
  padding: 15px 20px;
}

.link {
  display: flex;
  padding: .75rem 1.5rem;
  gap: 7px;
  transition: all 0.5s;
  align-items: center;
  /* color: #5B6B79; */
  color: #fff;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
}

.link:hover .icon {
  transition: all 0.5s;
}

.active {
  background: var(--coreIconbg);
  border-radius: 3px;
  /* color: var(--corewhite); */
  color: var(--primary);
}

.Modernactive {
  background: red;
  border-radius: 3px;
  color: blue !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #99a7ba7d !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.search_icon {
  position: absolute !important;
  top: 14px !important;
  color: #5B6B79 !important;
  left: 10px !important;

}

.side_heading {
  display: none !important;
  color: #99a7ba !important;
  font-size: .75rem;
  font-weight: 600;
  font-family: 'Poppins', sans-serif !important;
  text-transform: uppercase;
  padding-top: 18px;
  padding-left: 14px;
  letter-spacing: .03125rem;
  padding-bottom: 4px;
}

.text_icon,
.icon {
  font-size: 1.25rem;
}

.text,
.link_text {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  font-size: 0.813rem;
  transition: 0.5s;
  /* letter-spacing: 0.3px; */
}

.text:hover,
.link:hover .link_text {
  color: var(--primary);
  margin-left: 7px;
  transition: 0.5s;
}

.sidebar .css-1cw84h2 {
  top: 2px !important;
  left: 8px !important;
}

/* siderbar css end */


/*****navbar*****/
.ni_hed_clickk h3 {
  color: #00b4b4;
  font-size: 14px;
  font-weight: 600;
  text-align: end;
}

.ni_hed_clickk p {
  font-size: 12px;
  color: grey;
  font-weight: 500;
}

.ni_hed_click h3 {
  font-size: 14px;
  font-weight: 600;
  text-align: end;
  color: var(--primary);
}

.ni_hed_click P {
  font-size: 12px;
  color: grey;
  font-weight: 500;
}

.nav_wrap {
  background: var(--coreIconbg);
  width: 40px;
  height: 40px;
  margin-right: 15px !important;
  border-radius: 50px;
  color: var(--corecardtital) !important;
  cursor: pointer;

}

.nav_wrap :hover {
  color: #fff !important;
  background-color: var(--primary);
  width: 40px;
  height: 40px;
  border-radius: 50px;
  padding: 10px;
}

.dp:hover+.drop {
  display: block;
  color: #e9f3ff !important;

}

.drop {
  display: none;
}

.menu_setting h3 {
  font-size: 14px;
  font-weight: 800;
  color: #000;
}

.menu_setting p {
  font-size: 12px;
  font-weight: 500;
  color: grey;
}

.menu_item {
  padding: 14px !important;
  padding-left: 19px !important;
  border-top: 1px solid #e3e2e2 !important;
  background-color: #fff !important;
  /* color: #00000094 !important; */
  color: #0d99ff !important;
  font-size: 14px !important;
  font-weight: 500 !important;

}

.menu_item:hover {
  background-color: #e4eaf0 !important;
  transition: 0.5s;

}

.menu_list {
  transform: translate3d(-9.6px, 12px, 0px) !important;
  box-shadow: 0 10px 40px 10px rgb(140 152 164 / 18%) !important;
  border: none !important;
  margin-top: 10px !important;
  min-width: 256px !important;
}


.css-i857na:active,
.css-i857na[data-active],
.css-i857na {
  background: none !important;
}

/*******placeholder****/
/* textarea::placeholder {
    font-weight: 400 !important; 
  } */
/**********Scrollbar***********/
::-webkit-scrollbar {
  width: 2px;
  border-radius: 10px;


}

.scroll_port::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
  background-color: gray;
}

.scroll_port::-webkit-scrollbar-track {
  background: #CCCCCC;
}

.scroll_port::-webkit-scrollbar-thumb {
  background-color: #a5a5a5;
  border-radius: 5px;
}

.scroll_port::-webkit-scrollbar-thumb:hover {
  background-color: #444444ad;
}

/* ::-webkit-scrollbar {
    width: 3px; 
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #6ec2ff; 
    border-radius: 5px; 
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555; 
  } */
/* BellIcon.css */

@keyframes bell-ring {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-10deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(0deg);
  }
}



/* modern design */
.nav_men .active {
  color: #fff !important;
  background-color: #00b4b4;
}

.nav_men a {
  color: #00b4b4;
  margin: calc(0.7rem - 0.0625rem) 0;
  padding: .5rem .75rem;
  border-radius: 5px;
  transition: .5s;
}

.nav_men a span {
  color: #00b4b4;
}

.nav_men .active span {
  color: #fff !important;
}

#hello option {
  width: 300px !important;
}

 /* .striped-table tr:nth-child(odd) {
  background-color: #00b4b4; 
}

.striped-table tr:nth-child(even) {
  background-color: #ffffff; 
} */
.client-table td{
  padding-top: 7px!important;
  padding-bottom: 7px!important;
}
.menu-icon-f svg{
width: 1.5em;
height: 1.5em;
margin: auto;
}
.project_card{
  background-image: url(../assets//image/card-bg.png);
}