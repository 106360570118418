@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');

* {
  font-family: 'Montserrat', sans-serif !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F2F6F9 !important;
  font-family: 'Montserrat', sans-serif !important;
  /* overflow-x: hidden; */

}

code {
  font-family: 'Montserrat', sans-serif !important;
}

input::placeholder {
  font-size: 13px;
}

select:focus-visible,
.css-minvd6:focus-visible,
.css-minvd6[data-focus-visible],
.css-10dip6r:focus-visible,
.css-10dip6r[data-focus-visible],
.css-1cjy4zv:focus-visible,
.css-1cjy4zv[data-focus-visible] {
  box-shadow: none !important;
  outline: transparent;
}

td,
th {
  font-size: 12px !important;
}

th {
  font-weight: 600 !important;
  background: #f2f6f9;
}

td {
  font-weight: 500 !important;
}

label {
  font-size: 0.875rem !important;
}

.bar-table td {
  border: solid 1px;
  border-color: #E2E8F0;
  padding-top: 5px;
  padding-bottom: 5px;
}

.module-table td {
  padding-top: 10px;
  padding-bottom: 10px;
}

/****drag file**/
.drag_input_New {
  position: relative;
  border: dashed 2px #00b4b4;
  border-radius: 10px;
  background-color: #fff;
}

.drag_input {
  position: relative;
  border: dashed 2px grey;
  border-radius: 10px;
  background-color: #fff;
}

.drag_input_New input,
.drag_input input {
  cursor: pointer;
  height: 150px;
  opacity: 0;
  z-index: 1;
}

.drag_content {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  cursor: pointer;
}

.section {
  width: 100%;
  margin-bottom: 20px;
}

.nav {
  width: 20%;
  height: 100%;
  font-size: x-large;
  font-size: 13px;
  /* text-align: center; */
  margin: 10px 5px;
  /* display: flex;
  flex-direction: row;
  justify-content: space-around; */
  place-items: center;
}

.project_select span:first-child {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@keyframes sway {
  0% {
    transform: rotate(8deg);
  }

  50% {
    transform: rotate(-8deg);
  }

  100% {
    transform: rotate(8deg);
  }
}

.object {
  animation: sway 2.4s infinite;
  animation-timing-function: ease-in-out;
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  transform-origin: top;
  position: absolute;
  top: 0;
}

.object-shape {
  width: 200px;
  /* height: 54px; */
  display: block;
  background-color: red;
  margin: 0 auto;
  position: relative;
  text-align: center;
  color: #fff;
  border-radius: 5px;

}

.object-rope {
  height: 15px;
  width: 2px;
  background-color: red;
  content: "";
  display: block;
  margin-left: 50%;
  bottom: 54px;
  color: #fff;
}


.ovw {
  --c: #00b4b4;
  color: #00b4b4 !important;
  font-size: 16px;
  border: 0.1em solid var(--c);
  border-radius: 0.5em;
  width: 16em;
  height: 6em;
  text-transform: uppercase;
  font-weight: bold;
  font-family: sans-serif;
  letter-spacing: 0.1em;
  text-align: center;
  line-height: 6em;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 0.5s;
  margin: 1em;
}

.ovw span {
  position: absolute;
  width: 25%;
  height: 100%;
  background-color: var(--c);
  transform: translateY(150%);
  border-radius: 50%;
  left: calc((var(--n) - 1) * 25%);
  transition: 0.5s;
  transition-delay: calc((var(--n) - 1) * 0.1s);
  z-index: -1;
}

.ovw:hover {
  color: #fff !important;
}

.ovw:hover span {
  transform: translateY(0) scale(2);
}

.ovw span:nth-child(1) {
  --n: 1;
}

.ovw span:nth-child(2) {
  --n: 2;
}

.ovw span:nth-child(3) {
  --n: 3;
}

.ovw span:nth-child(4) {
  --n: 4;
}


.yesterdaymd::after {
  position: absolute;
  top: 15%;
  content: '';
  left: 35%;
  height: 58px;
  width: 2px;
  background-color: #00b4b4;
}
.yesterdayclassic::after {
  position: absolute;
  top: 15%;
  content: '';
  left: 35%;
  height: 58px;
  width: 2px;
  background-color: #4168F5;
  
}